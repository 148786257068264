.about-gallery {
  position: relative;
  overflow: hidden;
  z-index: 1;
  padding-top: size(120px);
  background-color: $color-default-black;

  @include vp-1023 {
    padding-top: size-t(120px);
  }

  @include vp-767 {
    padding-top: size-m(120px);
  }
}

.about-gallery__title {
  margin-bottom: size(60px);
  text-align: center;

  @include vp-1023 {
    margin-bottom: size-t(40px);
    font-size: size-t(36px);
  }

  @include vp-767 {
    margin-bottom: size-m(40px);
    font-size: size-m(26px);
    text-align: start;
  }
}
