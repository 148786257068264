.review-card {
  display: flex;
  flex-direction: column;
  gap: size(20px);

  padding: size(32px);
  height: size(272px);
  max-width: size(370px);

  color: $color-main-black;
  background-color: $color-default-white;

  border-radius: size(20px);

  @include vp-1023 {
    gap: size-t(20px);
    padding: size-t(20px);
    height: size-t(272px);
    max-width: size-t(370px);
    border-radius: size-t(16px);
  }

  @include vp-767 {
    gap: size-m(16px);
    padding: size-m(20px);
    height: size-m(272px);
    max-width: size-m(320px);
    border-radius: size-m(16px);
  }

  &__author {
    display: flex;
    align-items: center;
  }

  &__author-photo {
    margin-right: size(12px);
    width: size(48px);
    height: size(48px);
    border-radius: 50%;

    @include vp-1023 {
      margin-right: size-t(12px);
      width: size-t(56px);
      height: size-t(56px);
    }

    @include vp-767 {
      margin-right: size-m(12px);
      width: size-m(56px);
      height: size-m(56px);
    }
  }

  &__author-name {
    font-weight: 700;
  }

  &__text {
    line-height: 120%;

    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
  }

  &__link {
    text-decoration: underline;
    opacity: 0.5;

    transition: opacity $trans-default;

    @include hover {
      opacity: 1;
    }
  }
}
