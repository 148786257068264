.intro {
  position: fixed;
  display: flex;
  justify-content: center;
  padding-top: size(160px);
  min-height: 100vh;
  /* stylelint-disable-next-line */
  width: 100%;
  min-height: calc(100 * var(--vh, 1vh));

  overflow: hidden;

  @include vp-1023 {
    padding-top: size-t(120px);
  }

  &__cover {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    background-color: $color-default-black;
    width: 100%;
    height: 100%;

    opacity: 0;

    pointer-events: none;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: size(200px);
    background-image: $black-to-top;

    @include vp-1023 {
      height: size-t(200px);
    }

    @include vp-767 {
      height: size-m(200px);
    }
  }

  .container {
    display: flex;
    // align-items: center;
    padding-top: size(20px);
    padding-bottom: size(160px);
    width: 100%;

    @include vp-1023 {
      align-items: center;
      // padding-top: size-t(20px);
      padding-bottom: size-t(160px);
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: size(880px);

    @include vp-1023 {
      max-width: 100%;
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: size(30px);
    text-align: center;

    @include vp-1023 {
      margin-bottom: size-t(24px);
    }

    @include vp-767 {
      margin-bottom: size-m(24px);
    }
  }

  &__description {
    text-align: center;
    opacity: 0.7;

    max-width: size(680px);
    line-height: 120%;

    @include vp-1023 {
      max-width: 85%;
    }
  }

  // &__note {
  &__footer {
    position: absolute;
    z-index: 1;
    bottom: size(40px);

    @include vp-1023 {
      bottom: size-t(40px);
    }

    @include vp-767 {
      bottom: size-m(40px);
    }

    @include vp-374 {
      display: none;
    }
  }

  &__features-list {
    display: flex;
    gap: size(20px);

    @include vp-1023 {
      gap: size-t(8px);
    }

    @include vp-767 {
      display: grid;
      column-gap: size-m(20px);
      grid-template-columns: 1fr 1fr;
    }
  }

  &__button {
    align-items: center;
    justify-content: center;

    height: size(56px);
    width: size(280px);

    margin-top: size(40px);

    @include vp-1023 {
      height: size-t(56px);
      width: size-t(280px);
      margin-top: size-t(40px);
    }

    @include vp-767 {
      height: size-m(56px);
      width: size-m(280px);
      margin-top: size-m(40px);
    }
  }

  &__video-bg-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $color-default-black;
      opacity: 0.75;
    }
  }

  &__video-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    pointer-events: none;
  }
}
