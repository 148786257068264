.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));
  color: $color-default-white;
  background-color: $color-main-black;
  font-size: size(16px);
  line-height: 1;
  letter-spacing: 0.02em;
  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }
}

.is-after-intro {
  margin-top: 100vh;
}

.is-pointer-events {
  pointer-events: none;
}

.rouble {
  /* stylelint-disable */
  font-weight: 400 !important;
  /* stylelint-enable */
  // font-family: $ff-rouble;
  /* stylelint-disable */
  text-transform: lowercase !important;
  /* stylelint-enable */
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}
