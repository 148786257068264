.card {
  position: relative;
  padding: size(32px);
  height: size(350px);
  color: $color-default-white;
  border-radius: size(20px);
  overflow: hidden;

  @include vp-1023 {
    padding: size-t(20px);
    height: size-t(320px);
    border-radius: size-t(16px);
  }

  @include vp-767 {
    padding: size-m(20px);
    height: size-m(280px);
    border-radius: size-m(16px);
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: $color-main-black;
    opacity: 0.5;

    @include vp-767 {
      opacity: 0.65;
    }
  }

  &--link {
    background-image: $vermilion-to-scarlet;

    &::after {
      display: none;
    }

    @include hover-focus {
      .card__biceps {
        transform: scale(1.3);
      }
    }
  }

  &--button {
    padding: size(120px) size(20px) size(20px) size(20px);
    height: size(370px);

    @include vp-1023 {
      padding: size-t(120px) size-t(20px) size-t(20px) size-t(20px);
      height: size-t(300px);
    }

    @include vp-767 {
      padding: size-m(20px) size-m(20px) size-m(20px) size-m(20px);
      height: size-m(240px);
    }

    @include hover {
      .card__button {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
      }

      .card__image-wrapper {
        filter: blur(3px);
      }
    }
  }

  &__image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transition: filter $trans-default;

    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }

  &__content {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    // align-items: center;
    height: 100%;

    @include vp-767 {
      text-align: start;
    }

    &--mini {
      max-width: size(440px);

      @include vp-1023 {
        padding-top: size-t(40px);
        max-width: 100%;
      }

      @include vp-767 {
        padding-top: 0;
      }
    }
  }

  &__title {
    margin-top: 0;
    margin-bottom: size(20px);

    @include vp-1023 {
      margin-bottom: size-t(20px);
    }

    @include vp-767 {
      margin-bottom: size-m(20px);
    }
  }

  &__description {
    margin: 0;
    line-height: 125%;

    @include vp-767 {
      line-height: 130%;
    }
  }

  &__button.button {
    margin-top: size(20px);
    margin-left: auto;
    margin-right: auto;

    opacity: 0;
    transform: translateY(size(42px));

    transition: opacity $trans-default, transform $trans-default, background $trans-default;

    // pointer-events: none;

    @include vp-1023 {
      display: none;
    }

    @include focus {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  }

  &__biceps {
    position: absolute;
    right: size(-5px);
    bottom: size(32px);
    width: size(170px);
    height: size(206px);
    opacity: 0.3;

    transform: scale(1);
    transition: transform $trans-default;

    transform-origin: right bottom;

    @include vp-1023 {
      right: size-t(-5px);
      bottom: size-t(32px);
      width: size-t(170px);
      height: size-t(206px);
    }

    @include vp-767 {
      right: size-m(-5px);
      bottom: size-m(32px);
      width: size-m(170px);
      height: size-m(206px);

      opacity: 0.2;
    }
  }

  &__note {
    margin-top: auto;
    font-size: size(12px);

    @include vp-1023 {
      font-size: size-t(12px);
    }

    @include vp-767 {
      font-size: size-m(12px);
    }
  }
}
