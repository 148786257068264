// используйте для скрытия нежелательных анимаций-скачков при поворотах экрана
@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes pulse {
  from {
      transform: scale(0.8);
  }

  to {
      transform: scale(1.1);
  }
}
