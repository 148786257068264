.button-to-top {
  position: fixed;
  z-index: 100;
  bottom: size(60px);
  right: size(100px);

  display: flex;
  align-items: center;
  justify-content: center;

  width: size(60px);
  height: size(60px);

  color: $color-default-white;

  border-radius: 50%;
  background-image: $vermilion-to-scarlet;

  transition: all 0.6s ease;

  opacity: 0;
  transform: translateY(50%);

  pointer-events: none;
  visibility: hidden;

  @include vp-1023 {
    bottom: size-t(60px);
    right: size-t(40px);
    width: size-t(60px);
    height: size-t(60px);
  }

  @include vp-767 {
    bottom: size-m(60px);
    right: size-m(16px);
    width: size-m(50px);
    height: size-m(50px);
  }

  &.is-show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
    visibility: visible;
  }

  svg {
    width: size(32px);
    height: size(32px);
    transform: rotate(-90deg);

    @include vp-1023 {
      width: size-t(32px);
      height: size-t(32px);
    }

    @include vp-767 {
      width: size-m(28px);
      height: size-m(28px);
    }
  }

  @include hover-focus {
    transform: translateY(-10%);
  }
}
