.contacts {
  padding-bottom: size(120px);
  background-color: $color-transparent;

  @include vp-1023 {
    padding-bottom: size-t(120px);
  }

  @include vp-767 {
    padding-bottom: size-m(140px);
  }

  .page-section__description {
    max-width: size(504px);

    @include vp-1023 {
      max-width: size-t(504px);
    }
    @include vp-767 {
      max-width: 100%;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: size(32px);

    padding: 0;
    margin: 0;

    list-style: none;

    @include vp-1023 {
      gap: size-t(32px);
    }

    .contact-item {
      max-width: size(560px);

      @include vp-1023 {
        max-width: size-t(600px);
      }

      @include vp-767 {
        max-width: none;
      }
    }
  }

  .socials {
    margin-top: size(60px);

    @include vp-1023 {
      margin-top: size-t(60px);
    }

    @include vp-767 {
      margin-top: size-m(60px);
    }
  }
}
