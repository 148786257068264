.phone-message {
  width: 100%;
  height: 100%;

  background-color: $color-main-black;

  &__header {
    padding-top: size(24px);
    padding-bottom: size(10px);
    padding-right: size(14px);
    padding-left: size(30px);
    height: size(120px);
    background-color: rgba(29, 29, 29, 1);
  }

  &__author {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      margin-top: size(10px);
      font-size: size(12px);
    }
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;

    width: size(40px);
    height: size(40px);

    img {
      position: static;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    border-radius: 50%;
    background-color: #D8D8D8;
  }

  &__header-top {
    display: flex;
    justify-content: space-between;
  }

  &__time {
    width: size(24px);
    height: size(9px);
  }

  &__status-bar {

  }
}
