.services {
  padding-bottom: size(320px);

  @include vp-1023 {
    padding-bottom: 0;
  }

  .page-section__trigger {
    height: 250rem;

    @include vp-1023 {
      top: size-t(-150px);
      height: 40rem;
    }
  }

  .page-section__title-bg {
    width: size(1440px);

    @include vp-1023 {
      width: size-t(768px);
    }

    @include vp-767 {
      width: size-m(768px);
    }
  }

  &__inner {
    height: size(3641px);
    background-color: $color-main-black;

    @include vp-1023 {
      height: auto;
      overflow: hidden;
    }

    .page-section__header {
      position: sticky;
      top: size(82px);
      padding-bottom: size(20px);

      @include vp-1023 {
        position: static;
        margin-bottom: size-t(40px);
      }

      @include vp-767 {
        margin-bottom: size-m(40px);
      }
    }
  }

  .page-section__grid {
    overflow: visible;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: size(40px);
    grid-column-start: 2;

    @include vp-1023 {
      display: flex;
      flex-direction: row;
      grid-column-start: 1;
      gap: 0;
      grid-template-columns: 1fr 1fr;

      .card--sticky:last-child {
        order: 1;
      }

      .card--sticky:not(:last-child) {
        order: 2;
      }

      .card--sticky:nth-child(2) {
        order: 1;
      }
    }
  }

  &__content-inner {
    // position: sticky;
    // top: size(262px);

    .page-section__description {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &__content {
    margin-top: size(60px);
    max-width: size(600px);

    @include vp-1023 {
      margin-top: size-t(20px);
      max-width: size-t(600px);
    }

    @include vp-767 {
      margin-top: size-m(20px);
      max-width: 100%;
    }
  }

  &__link {
    display: inline-flex;
    margin-top: size(40px);
    color: $color-default-white;
  }

  .card {
    position: sticky;
    top: size(242px);

    box-shadow: 0 0 size(5px) rgba(0, 0, 0, 0.6);

    @include vp-1023 {
      position: relative;
      top: auto;
      width: size-t(340px);

      &:last-of-type {
        margin-right: 0;
      }
    }

    @include vp-767 {
      width: size-m(320px);
    }
  }
}
