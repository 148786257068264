.main-nav {
  &__list {
    display: flex;
    margin: 0;
    margin-right: size(50px);
    margin-left: size(60px);
    padding: 0;

    list-style: none;


    @include vp-1023 {
      display: grid;
      margin-right: 0;
      margin-left: 0;
      gap: size-t(20px);
    }

    @include vp-767 {
      gap: size-m(20px);
    }
  }

  &__item {
    margin-right: size(24px);

    @include vp-1023 {
      margin-right: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    position: relative;
    color: $color-default-white;
    transition: color $trans-default, font-weight $trans-default;

    @include vp-1023 {
      font-weight: 900;
      font-size: size-t(32px);
      text-transform: uppercase;
    }

    @include vp-767 {
      font-size: size-m(32px);
    }

    &::after {
      content: '';
      position: absolute;
      bottom: size(-6px);
      left: 0;

      width: 100%;
      height: size(2px);
      background-color: $color-vermilion;
      transition: transform $trans-default;
      transform-origin: left bottom;
      transform: scaleX(0);

      @include vp-1023 {
        height: size-t(2px);
        bottom: size-t(-6px);
      }
    }

    &.is-active {
      &::after {
        transform: scaleX(1);
      }
    }

    @include hover-focus {
      color: $color-vermilion;
    }
  }

  &__toggle {
    display: none;
  }
}
