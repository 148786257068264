.price {
  &__value-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include vp-1023 {
      width: size-t(200px);
    }

    @include vp-767 {
      width: 100%;
    }
  }

  &__note {
    position: absolute;

    font-size: size(28px);
    top: size(-10px);
    opacity: 0.5;

    @include vp-1023 {
      font-size: size-t(20px);
      top: size-t(-12px);
    }

    @include vp-767 {
      top: size-m(-16px);
      font-size: size-m(32px);
    }
  }

  &__splash {
    position: absolute;
    top: size(60px);
    z-index: 1;
    width: size(500px);
    height: size(80px);

    clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
    transition: all 0.35s ease-in-out 0.4s;

    @include vp-767 {
      top: size-m(60px);
      width: size-m(337px);
      height: size-m(42px);
    }

    &.is-active {
      clip-path: polygon(100% 0, 0 0, 0 100%, 100% 100%);
    }
  }

  &__value {
    position: relative;
    display: inline-flex;
    align-items: center;
    // width: size(250px);
    font-weight: 900;
    font-size: size(180px);
    line-height: 110%;

    &::after {
      content: '';
      position: absolute;

      width: 100%;
      height: size(10px);
    }

    span {
      font-size: size(220px);
    }

    @include vp-1023 {
      width: auto;
      font-size: size-t(140px);

      span {
        font-size: size-t(185px);
      }
    }

    @include vp-767 {
      font-size: size-m(150px);

      span {
        font-size: size-m(188px);
      }
    }
  }

  &__currency {
    position: absolute;
    font-weight: 400;
    font-size: size(48px);
    opacity: 0.5;

    @include vp-1023 {
      font-size: size-t(32px);
    }

    @include vp-767 {
      font-size: size-m(32px);
    }
  }

  &__duration {
    // position: absolute;
    // bottom: size(18px);
    margin-top: size(24px);
    padding: size(10px);
    font-weight: 400;
    font-size: size(32px);
    // opacity: 0.5;
    text-align: end;
    background-image: $vermilion-to-scarlet;

    @include vp-1023 {
      padding: size-t(10px);
      margin-top: size-t(10px);
      bottom: size-t(18px);
      font-size: size-t(20px);
    }

    @include vp-767 {
      padding: size-m(10px);
      margin-top: size-m(24px);
      font-size: size-m(32px);
    }
  }
}
