.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-default-black, 0.8);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal, transform $trans-modal, visibility $trans-modal;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    // align-items: center;
    width: 100%;
    min-height: 100%;
    padding: size(40px);


    @include vp-767 {
      padding: size(60px) size(16px);
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: size(840px);
    padding-top: size(40px);
    padding-bottom: size(40px);
    padding-left: size(40px);
    padding-right: size(40px);
    margin-bottom: auto;


    background-color: $color-default-white;

    border-radius: size(20px);

    @include vp-767 {
      width: 100%;
      padding: size(20px);
    }
  }

  &--main-form {
    // overflow: hidden;

    .modal__wrapper {
      padding: 0;
      // height: 100%;
    }

    .modal__content {
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }

  &__close-btn {
    position: absolute;
    top: size(0px);
    right: size(0px);
    z-index: 1;

    width: size(80px);
    height: size(80px);
    padding: 0;

    background: $color-transparent;
    border: none;
    cursor: pointer;

    transition: opacity $trans-default;

    @include vp-1023 {
      width: size-t(80px);
      height: size-t(80px);
    }

    @include vp-767 {
      width: size-m(60px);
      height: size-m(60px);
    }

    svg {
      width: size(40px);
      height: size(40px);

      @include vp-1023 {
        width: size-t(20px);
        height: size-t(20px);
      }

      @include vp-767 {
        width: size-m(24px);
        height: size-m(24px);
      }
    }

    @include hover-focus {
      opacity: 0.5;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &--feedback {
    .modal__content {
      margin: auto;
    }
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }

  &--success {
    .modal__wrapper {
      padding: 0;
    }

    .modal__content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
    }

    .modal__close-btn {
      color: $color-default-white;
    }
  }
}
