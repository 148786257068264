.custom-toggle {
  position: relative;
  display: flex;

  input {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;

    clip: rect(0 0 0 0);
  }

  label {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: size(12px);
    padding-bottom: size(12px);
    padding-right: size(16px);
    padding-left: size(52px);

    background-color: $color-default-black-3;
    border-radius: 2px;
    cursor: pointer;

    transition: background $trans-default;

    @include vp-1023 {
      padding-top: size-t(12px);
      padding-bottom: size-t(12px);
      padding-right: size-t(16px);
      padding-left: size-t(52px);
      border-radius: size-t(2px);
    }

    @include vp-767 {
      padding-top: size-m(12px);
      padding-bottom: size-m(12px);
      padding-right: size-m(16px);
      padding-left: size-m(52px);
      border-radius: size-m(2px);
    }

    span {
      margin-bottom: 0;
      margin-left: 0;
    }

    @include hover-focus {
      background-color: $color-default-black-10;
    }
  }

  &__label-note {
    display: block;
    margin-top: size(4px);

    font-size: size(12px);

    @include vp-1023 {
      margin-top: size-t(4px);
      font-size: size-t(12px);
    }

    @include vp-767 {
      margin-top: size-m(4px);
      font-size: size-m(12px);
    }
  }

  &--checkbox {
    .custom-toggle__icon {
      position: absolute;
      top: size(12px);
      left: size(16px);

      width: size(20px);
      height: size(20px);

      border: 1px solid $color-main-black;
      border-radius: size(2px);
      overflow: hidden;

      @include vp-1023 {
        top: size-t(12px);
        left: size-t(16px);

        width: size-t(20px);
        height: size-t(20px);

        border-radius: size-t(2px);
      }

      @include vp-767 {
        top: size-m(12px);
        left: size-m(16px);

        width: size-m(20px);
        height: size-m(20px);

        border-radius: 2px;
      }

      transition: border 0.3s ease;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        width: size(12px);
        height: size(12px);

        background-color: $color-transparent;

        transform: translate(-50%, -50%);

        transition: background-color 0.3s ease;

        @include vp-1023 {
          width: size-t(12px);
          height: size-t(12px);
        }

        @include vp-767 {
          width: size-m(12px);
          height: size-m(12px);
        }
      }
    }
  }

  &--radio {
    .custom-toggle__icon {
      position: absolute;
      top: size(12px);
      left: size(16px);

      width: size(20px);
      height: size(20px);

      border: 1px solid $color-main-black;
      border-radius: 50%;

      transition: border 0.3s ease;

      @include vp-1023 {
        top: size-t(12px);
        left: size-t(16px);

        width: size-t(20px);
        height: size-t(20px);
      }

      @include vp-767 {
        top: size-m(12px);
        left: size-m(16px);

        width: size-m(20px);
        height: size-m(20px);
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;

        width: size(12px);
        height: size(12px);

        color: rgba(255, 255, 255, 0);

        border-radius: 50%;
        transform: translate(-50%, -50%);

        transition: color 0.3s ease;

        @include vp-1023 {
          width: size-t(12px);
          height: size-t(12px);
        }

        @include vp-767 {
          width: size-m(12px);
          height: size-m(12px);
        }
      }
    }
  }

  input:checked + .custom-toggle__icon {
    border: 1.5px solid $color-vermilion;

    &::before {
      background-color: $color-vermilion;
    }
  }

  &.is-invalid {
    label span {
      color: #ff1553;
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &.is-invalid {
      color: #ff0000;
    }

    &.is-valid {
      color: #15ab07;
    }
  }
}
