.custom-input,
.custom-textarea {
  position: relative;

  display: flex;

  label {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: size(16px);

    @include vp-1023 {
      font-size: size-t(16px);
    }

    @include vp-767 {
      font-size: size-m(16px);
    }

    input,
    textarea {
      flex-grow: 1;
      min-height: size(48px);
      padding: size(10px);

      border: 2px solid rgba(#000000, 0.2);
      border-radius: size(2px);
      outline: none;

      font-size: size(16px);

      transition: border 0.3s ease;

      @include vp-1023 {
        min-height: size-t(48px);
        padding: size-t(10px);

        border: 2px solid rgba(#000000, 0.2);
        border-radius: size-t(6px);
        font-size: size-t(16px);
      }

      @include vp-767 {
        min-height: size-m(52px);
        padding: size-m(10px);

        border-radius: size-m(6px);
        font-size: size-m(16px);
      }

      &:hover {
        border: 2px solid rgba(#000000, 0.5);
      }

      &:focus {
        border: 2px solid rgba(#000000, 0.8);
      }
    }
  }

  &--vertical {
    label {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    input {
      margin-top: size(10px);

      @include vp-1023 {
        margin-top: size-t(10px);
      }

      @include vp-767 {
        margin-top: size-m(10px);
      }
    }
  }

  &__error {
    position: absolute;
    right: 0;
    bottom: 35px;

    color: $color-scarlet;

    opacity: 0;

    transition: opacity 0.3s ease;

    @media screen and (max-width: 767px) {
      bottom: 20px;
    }
  }

  &.is-invalid {
    input,
    textarea {
      border: 2px solid $color-scarlet;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-scarlet, 0.5);
      }
    }
  }

  &.is-valid {
    input {
      border: 2px solid #22b259;

      &:focus,
      &:hover {
        border: 2px solid rgba(#22b259, 0.5);
      }
    }
  }

  .input-message {
    position: absolute;
    right: 0;
    bottom: -5px;

    &.is-invalid {
      color: #ff0000;
    }

    &.is-valid {
      color: #15ab07;
    }
  }
}
