.container {
  width: 100%;
  max-width: size(1440px);
  margin: 0 auto;
  padding: 0 size(100px);

  @include vp-1023 {
    max-width: 100%;
    padding: 0 size-t(40px);
  }

  @include vp-767 {
    padding: 0 size-m(16px);
  }
}
